import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import optumLogo from './optum-logo.png'; // Add the logo image to your src folder
import { ClipLoader } from 'react-spinners'; // Import the spinner component

function App() {
  const [prompt, setPrompt] = useState('');
  const [topK, setTopK] = useState(20);
  const [best_k_match, setBestKMatch] = useState(10);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading spinner

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show spinner
    try {
      const response = await axios.get('/process-prompt', {
        params: {
          prompt: prompt,
          top_k: topK,
          best_k_match, best_k_match
        }
      });
      setResults(response.data);
    } catch (error) {
      console.error('There was an error fetching the data!', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <div className="App">
      <header>
        <img src={optumLogo} alt="Optum Logo" />
        <h1>MDP Attribute Search</h1>
      </header>
      <form onSubmit={handleSubmit}>
        <label>
          Prompt:
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            rows="5"
            placeholder="Enter your prompt here..."
          />
        </label>
        <label>
          Top K Results:
          <input
            type="number"
            value={topK}
            onChange={(e) => setTopK(Number(e.target.value))}
          />
        </label>
        <label>
          Best Match Count:
          <input
            type="number"
            value={best_k_match}
            onChange={(e) => setBestKMatch(Number(e.target.value))}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
      {loading ? (
        <div className="spinner">
          <ClipLoader size={50} color={"#004080"} loading={loading} />
        </div>
      ) : (
        results && (
          <div className="results">
            <h2>Best Match in Priority</h2>
            <div className="best-match" dangerouslySetInnerHTML={{ __html: results.best_match }} />
            <h2>Top {topK} Results</h2>
            <div className="result-table" dangerouslySetInnerHTML={{ __html: results.top_k_results }} />
          </div>
        )
      )}
    </div>
  );
}

export default App;
